import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import TeamItem from "../components/teamItem";
import TeamDetails from '../components/team-details';
import TeamCategory from '../components/team-category';
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isCategoryLoaded: false,
            categoryContent: [],
            current_path: null,
            catItems: null,
            category_name: null
        }
    }

    async componentDidMount() {
        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let category_name = id.params["*"];
        this.getAllTeams(category_name);
        this.getAllTeamCagory();
        this.getCMSCaterogy(category_name);
        this.setState({ current_path: this.props.location, category_name: category_name })
    }

    componentWillReceiveProps(nextProps) {
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let category_name = nextProps.params["*"];
        this.getAllTeams(category_name);
        this.getAllTeamCagory();
        this.getCMSCaterogy(category_name);
        this.setState({ current_path: nextProps.location, category_name: category_name })

    }

    async getAllTeamCagory() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/team")
            .then(res => res.json())
            .then(
                (result) => {
                    // const filteredDates = result.data.sort((a, b) => new Date(a.title) - new Date(b.title))
                    const filteredDates = result.data.sort((a, b) => a.order_id.localeCompare(b.order_id))
                    console.log("filteredDates", filteredDates)
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    async getAllTeams(category_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-category-name/" + category_name)
            .then(res => res.json())
            .then(
                (result) => {


                    let result2 = result.data.reduce(function (r, a) {
                        r[a.sub_category] = r[a.sub_category] || [];
                        r[a.sub_category].push(a);
                        return r;
                    }, Object.create(null));

                    let newdata = ['None', 'Of-Counsel', 'POSH Specialist', 'Senior Partners', 'Partners', 'Associates', 'Real Estate Specialist', 'Trainee Associates', 'Legal Executive']
                    let data = []
                    newdata.forEach((item) => {
                        let newDataArr = {
                            [item]: []
                        }
                        console.log("result.data", result.data)

                        Object.entries(result.data).forEach(([key, value], index) => {
                            if (value.sub_category === item) {
                                newDataArr[item].push(value)
                            }
                        })
                        data.push(newDataArr)
                    })

                    // Object.entries(result.data).forEach(([key, value], index) => {
                    //     // console.log("value", value)
                    //     console.log("key", key)
                    //     if (value.sub_category === 'Associates') {
                    //         console.log("index", index)
                    //         console.log("newdata newdata newdata ", index +  JSON.stringify(newdata[index]))
                    //         // newdata?.['Associates'][0].push(value)
                    //     }
                    //     if (value.sub_category === 'Trainee Associates') {
                    //         // newdata[index].trainee_associates[0].push(value)
                    //     }
                    // })
                    // console.log("newdata", newdata)

                    // const customOrder = ['Associates', 'Trainee Associates', 'Legal Executive']
                    // const filteredDates = result.data.sort((a, b) => a.title.localeCompare(b.title))
                    // console.log("by-category-name filteredDates", filteredDates)
                    this.setState({
                        isLoaded: true,
                        items: data,
                        customOrder: newdata
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    async getCMSCaterogy(category_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-category-name/" + category_name)
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data
                    console.log("by-category-name", filteredDates)
                    this.setState({
                        isLoaded: true,
                        catItems: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    render() {
        const { error, isLoaded, items, isCategoryLoaded, categoryContent, current_path, catItems, customOrder, category_name } = this.state;
        return (
            <Layout>
                {isLoaded ?
                    <Nav
                        active="team"
                        title={catItems?.document_title}
                        description={catItems?.meta_description}
                        keywords={catItems?.meta_tag}
                    ></Nav>
                    : ''}
                <Intro
                    search="true"
                    title="Our Team"
                    description="We have the largest team of professionals retained by a law firm in states of Telangana and Andhra Pradesh, with experience and expertise in diverse areas of practice.">
                </Intro>

                <div className="main-container" id="team-page">
                    <div className="team-outer-image">
                        <div id="practice-navigation-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div className="practice_navigation">
                                            {isCategoryLoaded ?
                                                <TeamCategory data={categoryContent} current_path={current_path}></TeamCategory>
                                                // <ul>
                                                //     {categoryContent.data.map(item => (
                                                //         <li key={item._id}>
                                                //             <Link 
                                                //             className={current_path.pathname == "/team-by-category/" + item.slug+"/" ? 'active': 'non-active' }
                                                //             to={"/team-by-category/" + item.slug + "/#team-page"}>{item.title}</Link>
                                                //         </li>
                                                //     ))}
                                                // </ul>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container team-outer-wrapper">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="blog-container">
                                        {/* {JSON.stringify(items)} */}
                                        {Object.entries(items).sort().map(([key, value], index) => (
                                            <div className="position-relative" id={key}>
                                                {Object.entries(value).sort().map(([key1, value1], index) => (
                                                    <>
                                                        {value1.length !== 0 ?
                                                            <>
                                                                {key1 === 'None' ? '' :
                                                                    Object.entries(items).length !== 1 ?
                                                                        <h3 className="headingPage"> {key1} </h3>
                                                                        : ''
                                                                }
                                                            </>
                                                            : ''
                                                        }

                                                        {value1.length !== 0 ?
                                                            value1.sort((a, b) => a.title.localeCompare(b.title)).map(item =>
                                                                <div key={item._id}>
                                                                    <ul>
                                                                        <React.Fragment>
                                                                            {item.category == "Associates" ?
                                                                                <TeamItem
                                                                                    key={item._id}
                                                                                    title={item.title}
                                                                                    slug={item.slug}
                                                                                    category={JSON.parse(item.additional_fields)}
                                                                                    profile={item.image}
                                                                                    ProfileLink={"/team-details/" + item.slug}
                                                                                >
                                                                                </TeamItem>
                                                                                :
                                                                                item.category == "Senior Associates" ?
                                                                                    <TeamItem
                                                                                        key={item._id}
                                                                                        title={item.title}
                                                                                        slug={item.slug}
                                                                                        category={JSON.parse(item.additional_fields)}
                                                                                        profile={item.image}
                                                                                        ProfileLink={"/team-details/" + item.slug}
                                                                                    >
                                                                                    </TeamItem>
                                                                                    :
                                                                                    item.category == "Chief Executive Officer" ?
                                                                                        <React.Fragment>
                                                                                            <TeamDetails data={item}> </TeamDetails>
                                                                                        </React.Fragment> :
                                                                                        item.category == "Founder & Managing Partner" ?
                                                                                            <React.Fragment>
                                                                                                <TeamDetails data={item}> </TeamDetails>
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <TeamItem
                                                                                                    key={item._id}
                                                                                                    title={item.title}
                                                                                                    slug={item.slug}
                                                                                                    category={JSON.parse(item.additional_fields)}
                                                                                                    profile={item.image}
                                                                                                    ProfileLink={"/team-details/" + item.slug}
                                                                                                >
                                                                                                </TeamItem>
                                                                                            </React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    </ul>
                                                                </div>

                                                            )
                                                            : ''
                                                        }
                                                    </>
                                                ))}
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        )
    }
}

export default IndexPage;

