import { Link } from "gatsby";
import React, { useState } from 'react';

export default function TeamCategory(props) {

    const setActive = ({ location: { pathname } }, active) => {

        console.log("props", props)
        // console.log("location", location)
        console.log("pathname", pathname)

        if (pathname === pathname) {
            return { className: "active" }
        } else {
            return { className: "" }
        }
    }


    const [isVisible, setIsVisible] = useState(false);
    const MegaMenu = () => {
        setIsVisible(!isVisible);
    }

    return (
        <React.Fragment>
            <div className="desktop">
                <ul>
                    {props.data.map(item => (
                        <li key={item._id}>
                            <Link
                                className={props.current_path.pathname == "/team-by-category/" + encodeURIComponent(item.title) + "/" ? 'active' : 'non-active'}
                                to={"/team-by-category/" + item.title + "/#team-page"}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="mobile" style={{width:220 * props.data.length + "px"}}>
                <ul>
                    {props.data.map(item => (
                        <li key={item._id}>
                            <Link
                                className={props.current_path.pathname == "/team-by-category/" + item.title + "/" ? 'active' : 'non-active'}
                                to={"/team-by-category/" + item.title + "/#team-page"}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            {/* <div className="mobile">
                {isVisible ?
                    <React.Fragment>
                        <div className="sub-navigation"> Team
                                    <button className="close" onClick={() => MegaMenu()}> </button>
                        </div>
                        <ul>
                            {props.data.map(item => (
                                <li key={item._id}  onClick={() => MegaMenu()}>
                                    <Link
                                        className={props.current_path.pathname == "/team-by-category/" + item.slug + "/" ? 'active' : 'non-active'}
                                        to={"/team-by-category/" + item.slug + "/#team-page"}>{item.title}</Link>
                                </li>
                            ))}
                        </ul>

                    </React.Fragment> :
                    <React.Fragment>

                        <div className="sub-navigation"> Team
                                    <button className="menu_mobile" onClick={() => MegaMenu()}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>



                    </React.Fragment>
                }
            </div> */}
        </React.Fragment>
    )
}
